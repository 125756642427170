import { ethers } from 'ethers';
import { BigNumber } from 'ethers';
import TokenSaleInfo from '../components/TokenSaleInfo';

export async function loadProvider(provider) {

  const signer = await provider.getSigner();
  return signer.getAddress();
	
	// const balance = await provider.getBalance(from);
  
	// const canSendTx = balance.gt(1e15);
	// if (canSendTx) return sendTx(registry.connect(signer), requestAmount);
	// else return sendMetaTx(registry, provider, signer, requestAmount);
}

export async function purchaseInUSD(provider, tokenSale, usdTokenAddress, amount, gasPrice, gasLimit) {
  if (!amount) throw new Error(`amount cannot be empty`);
  	console.log(`purchaseInUSD amount=${amount}`);

		const signer = await provider.getSigner();

		const from = await signer.getAddress();
		console.log(`purchaseInUSD amount=${ethers.utils.parseEther(amount)}`);

		const amountInDecimals = BigNumber.from(ethers.utils.parseEther(amount));

		const usdBalance = await usdTokenAddress.balanceOf(from);
		//const busdBalanceInDecimals = BigNumber.from(busdBalance.MAX_SAFE_INTEGER);

		console.log("BalanceUSD: " + usdBalance);
		console.log("AmountUSD: " + amountInDecimals);

		if(usdBalance.lt(amountInDecimals)){
			throw new Error(`You don't have enough BUSD`);
		}

		let allowance = await usdTokenAddress.allowance(from, tokenSale.address);
		console.log("Allowance: " + allowance);

		const allowanceInDecimals = BigNumber.from(allowance);

		console.log("AllowanceInDec: " + allowanceInDecimals);
		console.log("NET: " + amountInDecimals.sub(allowanceInDecimals));
		

		if(allowanceInDecimals.lt(amountInDecimals)){
			//let response = await usdTokenAddress.connect(signer).approve(tokenSale.address, (allowanceInDecimals.gt(0) ? (amountInDecimals.sub(allowanceInDecimals)) : amountInDecimals));
			let response = await usdTokenAddress.connect(signer).approve(tokenSale.address, (allowanceInDecimals.gt(0) ? amountInDecimals : amountInDecimals));
			await provider.waitForTransaction(response.hash, 1, 0);
		}

		if(gasPrice && gasLimit){
			const paramsWithGas = {
				gasPrice: gasPrice,
				gasLimit: gasLimit
			};
	
			return await tokenSale.connect(signer).purchaseInUSD(amountInDecimals, usdTokenAddress.address, paramsWithGas);
			//return await tokenSale.connect(signer).purchase(from, amountInDecimals, ethers.utils.keccak256(ethers.utils.toUtf8Bytes("USD")), paramsWithGas);
		}
		else{
			
			const gasLimit = await tokenSale.connect(signer).estimateGas.purchaseInUSD(amountInDecimals, usdTokenAddress.address);
			console.log('GAS: ' + gasLimit);
		
			const gasPrice = await signer.getGasPrice();
			console.log('GAS: ' + gasPrice);

	
			return {gasPrice: gasPrice, gasLimit: gasLimit}
		}
}

export async function purchaseInBNB(provider, tokenSale, amount, purchase, gasPrice, gasLimit) {

  if (!amount) throw new Error(`amount cannot be empty`)
  	console.log(`purchaseInBNB amount=${amount}`);

  	const signer = await provider.getSigner();

  	const from = await signer.getAddress();
  	console.log(`Address=${from}`);

	const bnbBalance = await signer.getBalance();
	if(bnbBalance.lt(ethers.utils.parseEther(amount))){
		throw new Error(`You don't have enough BNB`);
	}

	// const paymentAmount = await tokenSale.BNBperTokens(ethers.utils.parseUnits(purchase.toString(),10)).then(value => value);

	// console.log('Payment Amount: ' + ethers.utils.parseUnits(purchase.toString(),18).toString());
	// console.log('Payment Amount: ' + paymentAmount);
	// console.log('Payment Amount: ' + ethers.utils.formatEther(paymentAmount.toString(),10).toString());
	// console.log('Purchase Amount: ' + purchase);
	// console.log('Amount: ' + ethers.utils.parseEther(amount));
	// console.log('Amount: ' + parseFloat(paymentAmount.toString().substr(0,16)));

	// const payment = (paymentAmount.toString().substr(0,16))

	if(gasPrice > 0 && gasLimit > 0){
		const paramsWithGas = {
			value: ethers.utils.parseEther(amount),
			gasPrice: gasPrice,
			gasLimit: gasLimit
		};

		return await tokenSale.connect(signer).purchaseInBNB(paramsWithGas);
		//return await tokenSale.connect(signer).purchase(from, ethers.utils.parseEther(amount), ethers.utils.keccak256(ethers.utils.toUtf8Bytes("BNB")), paramsWithGas);
	}
	else{
		const params = {
			value: ethers.utils.parseEther(amount)
		};
		
		//const gasLimit = await tokenSale.connect(signer).estimateGas.purchase(from, ethers.utils.parseEther(amount), ethers.utils.keccak256(ethers.utils.toUtf8Bytes("BNB")), params);
		const gasLimit = await tokenSale.connect(signer).estimateGas.purchaseInBNB(params);
		console.log('GAS: ' + gasLimit);
		
		const gasPrice = await signer.getGasPrice();
		console.log('GAS: ' + gasPrice);

		return {gasPrice: gasPrice, gasLimit: gasLimit}
	}	
	
}

export async function addToWhitelist(provider, tokenSale, account) {
  if (!account) throw new Error(`address cannot be empty`)
  	console.log(`addToWhitelist account=${account}`);

  	const signer = await provider.getSigner();

	return tokenSale.connect(signer).addToWhitelist(account);
}

export async function loadTokenSaleInfo(provider, tokenSale){
	
	let tokenSaleInfoTemp = {};
	const tokenSaleInfo = await tokenSale.tokenSaleInfo().then(value => value);

	tokenSaleInfoTemp['maxPurchase'] = ethers.utils.formatEther(tokenSaleInfo['_maxPurchase']);
    tokenSaleInfoTemp['minPurchase'] = ethers.utils.formatEther(tokenSaleInfo['_minPurchase']);
    tokenSaleInfoTemp['bnbRate'] = tokenSaleInfo['_BNBRate'].toString();
    tokenSaleInfoTemp['usdRate'] = parseInt(tokenSaleInfo['_USDRate']);
	tokenSaleInfoTemp['bonusRate'] = parseFloat(tokenSaleInfo['_bonusRate']);
	tokenSaleInfoTemp['buntPrice'] = ethers.utils.commify(1 / tokenSaleInfoTemp['usdRate']);
	console.log('BNB PRICE: ' + ethers.utils.formatUnits(tokenSaleInfo['_BNBPrice'], 8));
	console.log('BNB PRICE: ' + tokenSaleInfo['_BNBPrice']);
	//const newBNBPrice = (parseFloat(tokenSaleInfo['_BNBPrice']) - (parseFloat((tokenSaleInfo['_BNBPrice'])) / (100000000)));
	//console.log('NEW BNB PRICE: ' + newBNBPrice);
	//tokenSaleInfoTemp['bnbPrice'] = parseFloat(newBNBPrice) / (100000000);
    //tokenSaleInfoTemp['bnbPrice'] = parseFloat(ethers.utils.formatUnits(newBNBPrice.toString(), 8));
	tokenSaleInfoTemp['bnbPrice'] = ethers.utils.formatUnits(tokenSaleInfo['_BNBPrice'], 8);
	//log('NEW NEW BNB PRICE: ' + tokenSaleInfoTemp['bnbPrice'] );

	//tokenSaleInfoTemp['bnbRate'] = parseFloat(tokenSaleInfoTemp['bnbPrice'] * tokenSaleInfoTemp['usdRate']);
	//tokenSaleInfoTemp['bnbRate'] = parseFloat(tokenSaleInfoTemp['bnbRate']);

	const maxPurchaseBunt = tokenSaleInfoTemp['maxPurchase'] * tokenSaleInfoTemp['usdRate'];
	const minPurchaseBunt = tokenSaleInfoTemp['minPurchase'] * tokenSaleInfoTemp['usdRate'];
	tokenSaleInfoTemp['maxPurchaseBunt'] = maxPurchaseBunt;
	tokenSaleInfoTemp['minPurchaseBunt'] = minPurchaseBunt;
	
	return tokenSaleInfoTemp;

}

export async function loadTokenSaleStats(provider, vesting, tokenSale){
	
	let tokenSaleStatsTemp = {};
	const tokenSaleStats = await tokenSale.tokenSaleStats().then(value => value);
	const vaultBalance = await vesting.vaultTotalAmount().then(value => value);

	const tokensPurchased = (parseFloat(vaultBalance) / 1000000000000000000) / parseFloat(1.5);
	const tokensGranted = parseFloat(tokensPurchased) / 2;

	tokenSaleStatsTemp['hardCap'] = ethers.utils.formatEther(tokenSaleStats['_hardCap']);
	tokenSaleStatsTemp['capRaised'] = parseFloat(tokensPurchased * 0.004).toFixed(2);
	tokenSaleStatsTemp['capToRaise'] = parseFloat(tokenSaleStatsTemp['hardCap'] - tokenSaleStatsTemp['capRaised']);
    tokenSaleStatsTemp['tokensAllocated'] = parseFloat(ethers.utils.formatEther(tokenSaleStats['_tokensAllocated'])).toFixed(2);
	tokenSaleStatsTemp['tokensPurchased'] = parseFloat(tokensPurchased).toFixed(2);
	tokenSaleStatsTemp['tokensRemaining'] = parseFloat(tokenSaleStatsTemp['tokensAllocated'] - tokenSaleStatsTemp['tokensPurchased']).toFixed(2);
	tokenSaleStatsTemp['tokensGranted'] = parseFloat(tokensGranted).toFixed(2);

	// tokenSaleStatsTemp['hardCap'] = await tokenSale.hardCap().then(value => value);
	// tokenSaleStatsTemp['capRaised'] = await tokenSale.capRaisedUSD().then(value => value);
	// tokenSaleStatsTemp['capToRaise'] = parseFloat(tokenSaleStatsTemp['hardCap']) - parseFloat(tokenSaleStatsTemp['capRaised']);

	// tokenSaleStatsTemp['tokensAllocated'] = await tokenSale.tokensAllocated().then(value => value);
	// tokenSaleStatsTemp['tokensPurchased'] = await tokenSale.tokensPurchased().then(value => value);

	// tokenSaleStatsTemp['tokensRemaining'] = parseFloat(tokenSaleStatsTemp['tokensAllocated']) - parseFloat(tokenSaleStatsTemp['tokensPurchased']);

	// tokenSaleStatsTemp['tokensGranted'] = await tokenSale.tokensGranted().then(value => value);

	// tokenSaleStatsTemp['hardCap'] = parseFloat(tokenSaleStatsTemp['hardCap'] / (1000000000000000000)).toFixed(2);
	// tokenSaleStatsTemp['capRaised'] = parseFloat(tokenSaleStatsTemp['capRaised']).toFixed(4);
	// tokenSaleStatsTemp['capToRaise'] = parseFloat(tokenSaleStatsTemp['capToRaise']).toFixed(4);
	// tokenSaleStatsTemp['tokensAllocated'] = parseFloat(tokenSaleStatsTemp['tokensAllocated'] / (1000000000000000000)).toFixed(2);
	// tokenSaleStatsTemp['tokensPurchased'] = parseFloat(tokenSaleStatsTemp['tokensPurchased'] / (1000000000000000000)).toFixed(2);
	// tokenSaleStatsTemp['tokensRemaining'] = parseFloat(tokenSaleStatsTemp['tokensRemaining'] / (1000000000000000000)).toFixed(2);
	// tokenSaleStatsTemp['tokensGranted'] = parseFloat(tokenSaleStatsTemp['tokensGranted'] / (1000000000000000000)).toFixed(2);
	
	return tokenSaleStatsTemp;

}

export async function loadFinalized(provider, tokenSale) {
  
	const response = await tokenSale.finalized().then(value => value);
	if(response === true){
		return 1;
	}
	else {
	return 0;
	}
}


export async function tokensPerUSD(provider, tokenSale, amount) {
	if (!amount) throw new Error(`amount cannot be empty`)
	//console.log(`amount per usd=${amount}`);
  
	  const response = await tokenSale.tokensPerUSD(ethers.utils.parseEther(amount.toString())).then(value => value.toString());
	  return response;
}

export async function totalCap (provider, tokenSale) {
  
	  const response = await tokenSale.tokensAllocated().then(value => value);
	  return response;
}

export async function capToRaise(provider, tokenSale) {
  
	  const response = await tokenSale.tokensRemaining().then(value => value);
	  return response;
}

export async function tokensWalletInfo(provider, vesting, tokensale) {
	
	try {
	const walletAddress = await loadProvider(provider);
	if(!walletAddress){
		return "";
	}
  
	const vestingEventInfo = await vesting.vestingEventInfo(tokensale.address).then(value => value);

	const vestingWalletInfo = await vesting.vestingWalletInfo(walletAddress, tokensale.address).then(value => value);

	const values = { 'wallet' : walletAddress,
					'token_amount' : ethers.utils.commify(parseFloat(ethers.utils.formatEther(vestingWalletInfo['amount'])).toFixed(2)),
					'firstClaim' : ethers.utils.commify(ethers.utils.formatEther(vestingWalletInfo['firstClaim'].toString())),
					'vestingPerPeriod' : vestingWalletInfo['vestingPerPeriod'].toString(),
					'cliff' : timeConverter(vestingEventInfo['cliff'].toString()),
					'periods' : vestingEventInfo['periods'].toString(),
					'firstClaimRate' : vestingEventInfo['firstClaimRate'].toString(),
					 'vestingRatePerPeriod' : ((100 - vestingEventInfo['firstClaimRate'])/vestingEventInfo['periods']).toString()
					};

	//console.log(`Wallet Info=${response['amount'].toString()}`);
	//return ethers.utils.commify(ethers.utils.formatEther(response['amount'].toString()));
	return values;
			}
			catch(err){
				console.error(err)
				return err;
			}
}

export async function isWhitelisted(address, tokensale) {

	try {	

		const whitelisted = await tokensale.whitelist(address).then(value => value);
		return whitelisted;
		
	}
	catch(err){
		console.error(err);
		return false;
	}

}

function timeConverter(UNIX_timestamp){
	var a = new Date(UNIX_timestamp * 1000);
	var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	var year = a.getFullYear();
	var month = months[a.getMonth()];
	var date = a.getDate();
	var hour = a.getHours();
	var min = a.getMinutes();
	var sec = a.getSeconds();
	var time = date + '/' + month + '/' + year; // + '  ' + hour + ':' + min + ':' + sec ;
	return time;
  }

