import { useRef, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import { EthereumContext } from '../eth/context';
import { createInstance } from '../eth/tokensale';
import { createInstance as createVestingInstance } from '../eth/vestingvault';
import { createBUSDInstance } from '../eth/busd';
import { createUSDTInstance } from '../eth/usdt';
import { ethers } from 'ethers';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Button, Dropdown } from 'semantic-ui-react'
import TokenSale from './TokenSale';
import TokenSaleInfo from './TokenSaleInfo';
import TokenSaleStats from './TokenSaleStats';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-spring-bottom-sheet/dist/style.css'
import { isWhitelisted } from '../eth/tokenSaleImp';

import './App.css';

function App() {

  const docsButtonStyle = {
    position: 'fixed',
    margin: '1.5em 2em',
    bottom: 0,
    right: 0,
    animation: 'back-to-docs 1.5s ease-in-out infinite',
    zIndex: 6,
    boxShadow: '15px 8px 16px rgba(44, 55, 75, 0.16)',
    backgroundColor:  'rgba(44, 55, 75, 0.16)', 
    borderRadius: '3rem',
    padding: '0.5rem 0.5rem'
  }

  const contactButtonStyle = {
    margin: '0 2em',
    animation: 'back-to-docs 1.5s ease-in-out infinite',
  }

  const style = (
    <style>
      {`
      @keyframes back-to-docs {
          0% { transform: translateY(0); }
          50% { transform: translateY(0.50em); }
          100% { transform: translateY(0); }
      }
    `}
    </style>
  )

  const [open, popSheet] = useState(true);
  const [connected, setConnected] = useState(false);
  const [whitelisted, setWhitelisted] = useState(false);

  const [provider, setProvider] = useState();
  const [walletAddress, setWalletAddress] = useState('');

  let [submitting, setSubmitting] = useState('Connect Wallet');

  const accountOptions = [
    { key: 'logout', icon: 'logout', text: 'Logout', value: 'logout' },
  ]

  useEffect(() => {
    popSheet(false);
  }, [])


  function onDismiss() {
    popSheet(false);
  }

  const start = async () => {
    
    try {
  
      let tempProvider = null;

      console.log(process.env.REACT_APP_CHAIN_ID_HEX);
      console.log(process.env.REACT_APP_CHAIN_ID);
      console.log(process.env.REACT_APP_CHAIN_NETWORK);
      console.log(process.env.REACT_APP_CHAIN_RPC_URL);
  
      if(window.ethereum){
          if(!connected){
            setSubmitting('Connecting Wallet...');

            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${Number(process.env.REACT_APP_CHAIN_ID).toString(16)}` }],
              });
            } catch (switchError) {
              // This error code indicates that the chain has not been added to MetaMask.
              if (switchError.code === 4902) {
                try {
                  await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                      {
                        chainId: `0x${Number(process.env.REACT_APP_CHAIN_ID).toString(16)}`,
                        chainName: process.env.REACT_APP_CHAIN_NETWORK,
                        nativeCurrency: {
                          name: 'Binance',
                          symbol: 'BNB', 
                          decimals: 18
                        },
                        blockExplorerUrls: [process.env.REACT_APP_CHAIN_EXPLORER],
                        rpcUrls: [process.env.REACT_APP_CHAIN_RPC_URL],
                      },
                    ],
                  });
                } catch (addError) {
                  setSubmitting('Connect Wallet');
                  toast(`Error adding BSC Smart Chain to wallet`, { type: 'error' });
                  return;
                }
              }
              setSubmitting('Connect Wallet');
            }
            

            await window.ethereum.request({ method: 'eth_requestAccounts' });
            tempProvider = new ethers.providers.Web3Provider(window.ethereum);
            setProvider(tempProvider);
          }
          else{
              setProvider(null);
              setConnected(false);
              setSubmitting('Connect Wallet');
              return;
          }
        }
        else{

          const bridge = "https://bridge.walletconnect.org";
          const walletConnectProvider = new WalletConnectProvider({
            infuraId: "27e484dcd9e3efcfd25a83a78777cdf1", // Required
            bridge: bridge,
            qrcode: true,
            chainId: parseInt(process.env.REACT_APP_CHAIN_ID),
            rpc: {
              56: process.env.REACT_APP_CHAIN_RPC_URL,
              97: process.env.REACT_APP_CHAIN_RPC_URL
                }
            });
          
          if(walletConnectProvider.connector.connected && connected){
            await walletConnectProvider.connector.killSession();
            setSubmitting('Connect Wallet');
            setConnected(false);
            return;
  
          }
          else{
            setSubmitting('Connecting Wallet...');
            await walletConnectProvider.enable();     
          } 
  
          tempProvider = new ethers.providers.Web3Provider(walletConnectProvider);

          const userNetwork = await tempProvider.getNetwork();

          if (userNetwork.chainId !== parseInt(process.env.REACT_APP_CHAIN_ID))
          {
            toast(`Please switch to BSC Smart Chain`, { type: 'error' });
            await walletConnectProvider.connector.killSession();
            setSubmitting('Connect Wallet');
            return;
          }
  
        }
  
        const userNetwork = await tempProvider.getNetwork();

        console.log('NETWORK ID: ' + userNetwork.chainId);
        console.log('ENV ID: ' + process.env.REACT_APP_CHAIN_ID);

        if (userNetwork.chainId !== parseInt(process.env.REACT_APP_CHAIN_ID))
        {
          toast(`Please switch to BSC Smart Chain`, { type: 'error' });
          setSubmitting('Connect Wallet');
          return;
        }
  
        const signer = tempProvider.getSigner();
        if (!signer.getAddress()) toast(`No Address`, { type: 'error' });
  
        setProvider(tempProvider);
            
        console.log('PROVIDER: ' + userNetwork.chainId);
        const address = await signer.getAddress();
        const optimizedAddress = address.toString().substr(0, 5) + "..." + address.toString().substr(-5, 5);

        console.log('ADDRESS: ' + address.toString());
        console.log('ADDRESS: ' + optimizedAddress);

        setWalletAddress(optimizedAddress);
        
        try {
        const tokensale = createInstance(tempProvider);
        const whitelistedAccount = await tokensale.whitelist(address.toString()).then(value => value);

        console.log('IS WHITELISTED: ' + whitelistedAccount);

        setWhitelisted(whitelistedAccount);
        }
        catch(err){
          console.log(err.message);
        }

        setSubmitting('Disconnect Wallet');
        setConnected(true);
        
      } catch(err) {
        //toast(err.message || err, { type: 'error' });
        console.log(err.message);
        setSubmitting('Connect Wallet');
      } finally {
        //setSubmitting('Disconnect Wallet');
      }
  }

  const connectWallet = async (event) => {
    event.preventDefault();
    start();
  }
  
  const joinWhiteList = async (event) => {
    window.open(`https://docs.google.com/forms/d/e/1FAIpQLSf14tkIn-4x7h-L2IpjRjHTt3mcHuqetGJowbRYVC90SO4aDg/viewform`);
  }
  

  function isProviderSupported() {
    if (connected){

      const tokensale = createInstance(provider);
      const busd = createBUSDInstance(provider);
      const usdt = createUSDTInstance(provider);
      const vesting = createVestingInstance(provider);

      const ethereumContext = { provider, tokensale, busd, usdt, vesting, popSheet }

      // const accountWhitelisted = isWhitelisted(provider, tokensale);
      // console.log('IS WHITELISTED: ' + accountWhitelisted);
      // setWhitelisted(accountWhitelisted);

      console.log("WHITELISTED ADDRESS: " + whitelisted);

      if(whitelisted){

        return <div className="App-Container"><header className="App-header">
        <div className="App-subject">

          </div>
        </header>
  
        <div className="Account-Control">
          <Button.Group size='tiny'>
            <Button className='ui color1 button' size='tiny' style={{width:'auto'}}>{walletAddress}</Button>
            <Dropdown
              className='ui color1 button'
              floating
              options={accountOptions}
              onChange={connectWallet}
              selectOnBlur={false}
              trigger={<></>}
            />
          </Button.Group>
          </div>
        <section className="App-content">
          <EthereumContext.Provider value={ethereumContext}><TokenSaleStats /><TokenSaleInfo />
            <BottomSheet
            open={open}
            onDismiss={onDismiss}
            header={
              <div>
              <h3 className="flex items-center text-xl justify-center font-bold text-gray-800">
                Token Sale
              </h3>
              <div className="Close-Sheet">
                <Button onClick={onDismiss} color="grey" icon='close' circular='true' size='mini' style={{width:'auto'}}/>
              </div>
              </div>
            }
            >
                <TokenSale />
              </BottomSheet>
            </EthereumContext.Provider>;
            </section>

            <div className="tail">
              <p>Powerd by Buntchain © Copyright 2022. All rights reserved</p>
              <p>www.buntchain.com   -   info(at)buntchain.com</p>
              <p></p>
            </div>
            </div>
        }

        else{

        return <div className="App-Container"><header className="App-header">
        <div className="App-subject">
          </div>
        </header>
  
        <div className="Account-Control">
          <Button.Group size='tiny'>
            <Button className='ui color1 button' size='tiny' style={{width:'auto'}}>{walletAddress}</Button>
            <Dropdown
              className='ui color1 button'
              floating
              options={accountOptions}
              onChange={connectWallet}
              trigger={<></>}
            />
          </Button.Group>
          </div>

          <div className="Whitelisting-Context">
              <h2>Participation in pre-seed sale event is currently available for invited contributors</h2>
              <p>Interested in participation? Join pre-seed sale NOW...
              </p>
              
          </div>

          <div className="tokensale-join">
            <form onSubmit={joinWhiteList}>
                <a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSf14tkIn-4x7h-L2IpjRjHTt3mcHuqetGJowbRYVC90SO4aDg/viewform' class="Connect-Wallet-Intro-Button" type="submit">Request Invitation</a>
              </form>
            </div>
            
            <br/>

          <div style={contactButtonStyle}>
          <Button class="ui circular icon button" as='a' href='https://discord.gg/P3m2PCnMM4' icon='discord' color='violet' target='_blank' circular="true"  size="large" style={{width:'auto', margin:'0 1rem'}}/>
          <Button class="ui circular icon button" as='a' href='https://t.me/BUNTCHAINOFFICIAL'icon='telegram' color='blue' target='_blank' circular="true" size="large" style={{width:'auto', margin:'0 1rem'}}/>
          <Button class="ui circular icon button" as='a' href='https://github.com/buntchain' icon='github' color='black' target='_blank' circular="true" size="large" style={{width:'auto', margin:'0 1rem'}}/>
        </div>

        <div className="tail2">
              <p>Powerd by Buntchain © Copyright 2022. All rights reserved</p>
              <p>www.buntchain.com   -   info(at)buntchain.com</p>
              <p></p>
            </div>
        
          </div>

        }
     }
     else {
       return <div className="Intro-Context">
      
            <div className="Join-Context">
              <p>BUNT Token PRE-SEED Sale Started</p>
            </div>

            <div className="Summary-Context">
            <p>Let us together bring <span>coffee</span> into the Blockchain</p>

              <form onSubmit={connectWallet}>
                <button class="Connect-Wallet-Intro-Button" type="submit">{submitting}</button>
              </form>

            </div>

            <div className="tail2">
              <p>Powerd by Buntchain © Copyright 2022. All rights reserved</p>
              <p>www.buntchain.com   -   info(at)buntchain.com</p>
              <p></p>
            </div>

        </div>
     }
    //  else {
    //   <div>
    //   <div className="Account-Control">
    //   <Button.Group size='tiny'>
    //     <Button className='ui color1 button' size='tiny' style={{width:'auto'}}>{walletAddress}</Button>
    //     <Dropdown
    //       className='ui color1 button'
    //       floating
    //       options={accountOptions}
    //       onChange={connectWallet}
    //       trigger={<></>}
    //     />
    //   </Button.Group>
    //   </div>

    //   <div className="Summary-Context">
    //   <p>The seed sale event is currently available for invited contributors only</p>
    //   <p>Stay tuned for next Bunt token sale events</p>
    //   </div>
    //   </div>

    //  }
   }

  return (
    <div className="App">
      {style}
      <div style={docsButtonStyle} hidden={connected && !whitelisted}>
          <Button class="ui circular icon button" as='a' href='https://discord.gg/P3m2PCnMM4' icon='discord' color='violet' target='_blank' circular="true"  size="big" style={{width:'auto'}}/>
          <Button class="ui circular icon button" as='a' href='https://t.me/BUNTCHAINOFFICIAL' icon='telegram' color='blue' target='_blank' circular="true" size="big" style={{width:'auto'}}/>
          <Button class="ui circular icon button" as='a' href='https://github.com/buntchain' icon='github' color='black' target='_blank' circular="true" size="big" style={{width:'auto'}}/>
        </div>
      <div className="logo"/>
      
      
      {isProviderSupported()}      

      <ToastContainer/>
    </div>
  );
}

export default App;