import { useRef, useState, useEffect, useContext } from 'react';
import { EthereumContext } from "../eth/context";
import { toast } from 'react-toastify';
import 'semantic-ui-css/semantic.min.css'
import './Tokensale.css';
import { Progress } from 'semantic-ui-react'
import { ethers } from 'ethers';
import { loadTokenSaleStats } from '../eth/tokenSaleImp';
import { tokensWalletInfo } from '../eth/tokenSaleImp';
import { loadFinalized } from '../eth/tokenSaleImp';

import buntLogo from "../images/bunt_coin.png";

function TokenSaleStats() {

  const [submitting, setSubmitting] = useState(false);
  const [finalized, setFinalized] = useState(-1);
  

  let { tokensale, busd, provider, vesting, walletConnectProvider, popSheet } = useContext(EthereumContext);

  //const token_sale_stats = { 'tokens_per_usd' : 'loading', 'tokens_cap_for_sale' : 'loading', 'tokens_available_for_sale' : 'loading'};
  const token_sale_stats_values = { 'tokens_per_usd' : 1 };

  let [token_sale_stats, setTokenSaleStats] = useState({});
  let [ walletStatus, setWalletStatus] = useState(true);
  let [ walletInfo, setValue] = useState({});

  useEffect(() => {
    const updateStats = async() => {  
      try {
        finalized = setFinalized(await loadFinalized(provider, tokensale));
    
  } catch (err) {
    console.error(err);
  }
    }

    updateStats();
    
    return () => [];
  }, []);

  useEffect(() => {
    const updateStats = async() => {  
      try {
        walletInfo = setValue(await tokensWalletInfo(provider, vesting, tokensale));
    
  } catch (err) {
    console.error(err);
  }
    }

    updateStats();
    
    return () => [];
  }, []);


  useEffect(() => {
    const updateStats = async() => {  
        try {
          token_sale_stats = setTokenSaleStats(await loadTokenSaleStats(provider, vesting, tokensale));
          providerState();
      
    } catch (err) {
      console.error(err);
    }
  }

    updateStats();
    
    return () => [];
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        token_sale_stats = setTokenSaleStats(await loadTokenSaleStats(provider, vesting, tokensale));
        providerState();
        
      } catch (err) {
        console.error(err);
      }
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {

    const interval = setInterval(async () => {
      try {            
            walletInfo = setValue(await tokensWalletInfo(provider, vesting, tokensale));
            providerState();
        
      } catch (err) {
        console.error(err);
      }
    }, 20000);
    return () => clearInterval(interval);
  }, []); 

    async function providerState() {
    //     try{
    //     const signer = await provider.getSigner();
    //     const address = await signer.getAddress();
    //     if (address) {
    //     //setSubmitting('Connect Wallet');
    //     setWalletStatus(true);
    //     }
    //     else{
    //     //setSubmitting('Disconnect Wallet');
    //     setWalletStatus(true);
    //     }
    // }
    // catch(err){
    //     setWalletStatus(true);
    // }
    }

  return <div className="Container-TokenSaleStats">

  <div className="tokensale-intro">
    <div className="tokensale-welcome">
        <p className="tokensale-welcome-cta">PRE-SEED BUNT CHAIN TOKEN SALE</p>
        <p className="tokensale-welcome-summary">to bring coffee supply chain into the blockchain and empower those behind your daily & lovely cup of coffee and enable them survive and compete on a global scale</p>
        <button className="buy-now" hidden={ finalized === -1 ? true : (finalized === 1 ? true : false) } onClick={() => popSheet(true)}>Buy BUNT NOW</button>
        <p className="tokensale-finalized" hidden={ finalized === -1 ? true : (finalized === 0 ? true : false) }>Pre-Seed Sale is finalized. Join our community for upcoming token sale updates</p>
      </div>
    <div className="tokensale-wallet">
    <div className="tokensale-wallet-container">
        <div className="tokensale-wallet-title">
          Your BUNT Wallet
        </div>
        <div className="tokensale-wallet-balance">
        <div className="tokensale-wallet-balance-amount">
          <p>{walletInfo.token_amount ? walletInfo.token_amount : '...'}</p>
        </div>
        <div className="tokensale-wallet-logo" hidden={!walletInfo.token_amount}>
        <img src={buntLogo}></img>
        </div>
        </div>
        <span>{walletInfo.token_amount ? '(locked)' : ''}</span>
    </div>
  </div>
  </div>

    <div className="tokensale-Progress">
      <div style={{width:"100%"}}>
      <div className="tokensale-Progress-title">
        Tokens Sale - Cap Raised so Far ($USD)
      </div>
      <div className="tokensale-Progress-header">
      <div style={{float:"left"}}>
        {token_sale_stats.capRaised ? '$'+ ethers.utils.commify((token_sale_stats.tokensPurchased / 250).toFixed(2)) : '' }
        </div>
        <div style={{float:"right"}}>
        {token_sale_stats.hardCap ? '$'+ ethers.utils.commify(token_sale_stats.hardCap) : '' }
        </div>
      </div>
      <div>
      <Progress percent={(token_sale_stats.tokensPurchased / token_sale_stats.tokensAllocated * 100).toFixed(2)} color='teal' progress='percent' active>
      </Progress>
      </div>
      </div>
    </div>

    <div className="tokensale-finance">
      <div className="tokensale-finance-header">
        Tokens Sale Max Cap
    </div>
    <div className="tokensale-finance-content" >
        { token_sale_stats.tokensAllocated ? ethers.utils.commify(token_sale_stats.tokensAllocated) + ' BUNT' : '...'} <p>{token_sale_stats.hardCap ? '($'+ ethers.utils.commify(parseFloat(token_sale_stats.hardCap).toFixed(4).toString()) + ')' : '' }</p>
    </div>
    </div>
    <div className="tokensale-finance">
      <div className="tokensale-finance-header">
        Tokens Purchased so Far
    </div>
    <div className="tokensale-finance-content">
    { token_sale_stats.tokensPurchased ?  (ethers.utils.commify(token_sale_stats.tokensPurchased)) + ' BUNT' : '...'} <p>{token_sale_stats.capRaised ? '($'+ ethers.utils.commify((token_sale_stats.tokensPurchased / 250).toFixed(4)) + ')' : '' }</p>
    </div>
    </div>
    <div className="tokensale-finance">
      <div className="tokensale-finance-header">
        Tokens Granted (50% Bonus)
    </div>
    <div className="tokensale-finance-content">
    { token_sale_stats.tokensGranted && parseFloat(token_sale_stats.tokensGranted) > 0 ? ethers.utils.commify(token_sale_stats.tokensGranted) + ' BUNT' : '0.0 BUNT'} <p>{token_sale_stats.tokensGranted && parseFloat(token_sale_stats.tokensGranted) > 0 ? '($'+ ethers.utils.commify(((token_sale_stats.tokensPurchased / 250) / 2 ).toFixed(4)) + ')' : '($0.0)' }</p>
    </div>
    </div>
    
  </div>
  
}

export default TokenSaleStats;