import { useRef, useState, useEffect, useContext } from 'react';
import { EthereumContext } from "../eth/context";
import { tokensWalletInfo } from '../eth/tokenSaleImp';
import buntLogo from "../images/bunt_coin.png";
import { Label } from 'semantic-ui-react';
import { loadTokenSaleInfo } from '../eth/tokenSaleImp';
import { loadTokenSaleStats } from '../eth/tokenSaleImp';
import { ethers } from 'ethers';

import './Tokensale.css';

function TokenSaleInfo() {

  var { tokensale, vesting, provider } = useContext(EthereumContext);

  let [ walletInfo, setValue] = useState({});
  const [tokenSaleInfo, setTokenSaleInfo] = useState({});
  const [tokenSaleStats, setTokenSaleStats] = useState({});

  let [ walletStatus, setWalletStatus] = useState(true);

  let [submitting, setSubmitting] = useState('Connect Wallet');

  useEffect(() => {
    const getTokenSaleInfo = async() => {  
      try {

      const tokenSaleInfoTemp = await loadTokenSaleInfo(provider, tokensale);

      console.log(tokenSaleInfoTemp);

      setTokenSaleInfo(tokenSaleInfoTemp);

      }
      catch(err){
        console.error(err)
      }
    }
    
    getTokenSaleInfo();
    return () => [];
  }, []);

  useEffect(() => {
    const getTokenSaleStats = async() => {  
      try {

      const tokenSaleStatsTemp = await loadTokenSaleStats(provider, vesting, tokensale);

      console.log(tokenSaleStatsTemp);

      setTokenSaleStats(tokenSaleStatsTemp);

      }
      catch(err){
        console.error(err)
      }
    }
    
    getTokenSaleStats();
    return () => [];
  }, []);

  useEffect(() => {
    const updateWalletInfo = async() => {  
      try {
        walletInfo = setValue(await tokensWalletInfo(provider, vesting, tokensale));
        providerState();
    
  } catch (err) {
      console.error(err);
  }
    }

      updateWalletInfo();
    
    return () => [];
  }, []);
  

  useEffect(() => {

    const interval = setInterval(async () => {
      try {
            walletInfo = setValue(await tokensWalletInfo(provider, vesting, tokensale));
            providerState();
        
      } catch (err) {
        console.error(err);
      }
    }, 15000);
    return () => clearInterval(interval);
  }, []);   
   

async function providerState() {
 
  }
  
  
  return <div className="Container-TokenSaleInfo">

<div className="tokensale-finance-token-container">
<div className="tokensale-finance-token">
  <div className="tokensale-finance-token-header">
  Tokenomics
</div>
<img src={buntLogo}/>
</div>
<Label content=' 50% Bonus ' color='teal' size='large' circular/>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Token SYMBOL</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>BUNT</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Token Decimals</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>18</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Token Price</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{ tokenSaleInfo.buntPrice ? '$ ' + ethers.utils.commify(parseFloat(tokenSaleInfo.buntPrice).toFixed(6)) : '...'}</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Tokens per USD</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{tokenSaleInfo.usdRate ? ethers.utils.commify(tokenSaleInfo.usdRate) + ' BUNT': '...'}</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Seed Sale Bonus</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{tokenSaleInfo.bonusRate ? tokenSaleInfo.bonusRate + ' %' : '...'}</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Min. Purchase</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{tokenSaleInfo.minPurchaseBunt ? '$' + ethers.utils.commify(parseFloat(tokenSaleInfo.minPurchaseBunt * tokenSaleInfo.buntPrice).toFixed(2)) : '...'}</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Max. Purchase</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{tokenSaleInfo.maxPurchaseBunt ? '$' + ethers.utils.commify(parseFloat(tokenSaleInfo.maxPurchaseBunt * tokenSaleInfo.buntPrice).toFixed(2)) : '...'}</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Hard Cap</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{tokenSaleStats.hardCap ? '$'+ ethers.utils.commify(tokenSaleStats.hardCap) : '...'}</p>
</div>
</div>

<br/>
<div className="tokensale-finance-token-container">
<div className="tokensale-finance-token">
  <div className="tokensale-finance-token-header">
  Distribution
  </div>
<img src={buntLogo}/>
</div>
<Label content=' Vesting ' color='teal' size='large' circular/>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Vesting Plan</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>Monthly</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Vesting Period</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{  walletInfo.periods ? walletInfo.periods + ' months' : "..." }</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Cliff End (TBD)</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{  walletInfo.cliff ? walletInfo.cliff : "..." }</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>First Dist. after Cliff</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{  walletInfo.firstClaimRate ? walletInfo.firstClaimRate + '%' : "..." }</p>
</div>
</div>

<div className="tokensale-finance-tokenomics">
<div className="tokensale-finance-tokenomics-header">
  <p>Dist. per Period</p>
</div>
<div className="tokensale-finance-tokenomics-content">
  <p>{  walletInfo.vestingRatePerPeriod ? walletInfo.vestingRatePerPeriod + ' %' : "..." }</p>
</div>
</div>


{/* <div className="tokensale-finance">
  <div className="tokensale-finance-header">
  {  walletStatus ? "Your Bunt tokens" : "" }
</div>
<div className="tokensale-finance-content" >
    {  walletInfo['token_amount'] ? walletInfo['token_amount'] : "..." } <p>{ walletInfo['token_amount'] ? '(locked)' : ''}</p>
</div>
</div>

<div className="tokensale-finance">
  <div className="tokensale-finance-header">
  {  walletStatus ? "Vesting starts at" : "" }
</div>
<div className="tokensale-finance-header" >
    <b>{  walletInfo['cliff'] ? walletInfo['cliff'] : "..." } </b>
</div>
</div>

<div className="tokensale-finance">
  <div className="tokensale-finance-header">
  {  walletStatus ? "First vested tokens amount (1 month after cliff)" : "" }
</div>
<div className="tokensale-finance-header" >
    <b>{  walletInfo['firstClaim'] ? walletInfo['firstClaim'] + ' BUNTs  (' + walletInfo['firstClaimRate']+'%) ' : "..." } </b>
</div>
</div> */}

</div>;
  
  
}

export default TokenSaleInfo;