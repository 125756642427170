import { ethers } from 'ethers';
import { useRef, useState, useEffect, useContext } from 'react';
import { EthereumContext } from "../eth/context";
import { toast } from 'react-toastify';
import { addToWhitelist } from '../eth/tokenSaleImp';
import { purchaseInBNB } from '../eth/tokenSaleImp';
import { purchaseInUSD } from '../eth/tokenSaleImp';
import { tokensPerBUSD } from '../eth/tokenSaleImp';
import { totalCap } from '../eth/tokenSaleImp';
import { capToRaise } from '../eth/tokenSaleImp';
import { loadTokenSaleFinance } from '../eth/tokenSaleImp';
import {isMobile} from 'react-device-detect';
import { Dropdown, Input, Icon, Label, IconGroup, Button } from 'semantic-ui-react'
import bnbLogo from "../images/bnb.png";
import busdLogo from "../images/busd.png";
import buntLogo from "../images/bunt.png";
import usdtLogo from "../images/usdt.png";
import { loadTokenSaleInfo } from '../eth/tokenSaleImp';
import { BigNumber } from 'ethers';

import './Tokensale.css';

function TokenSale() {
  const whitelistInput = useRef(null);
  const paymentAmountInput = useRef(null);
  const purchaseAmountInput = useRef(null);

  let [balanceInfo, setBalanceInfo] = useState({});
  const [tokenSaleInfo, setTokenSaleInfo] = useState({});

  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');

  const [validPurchase, setValidPurchase] = useState(false);
  const [purchaseConfig, setPurchaseConfig] = useState({message: 'Proceed Purchase', color: 'grey'});
  const [estimatedGas, setEstimatedGas] = useState({gasPrice: 0, gasLimit: 0});
  const [confirmStage, setConfirmStage] = useState(false);
  const [transactionConfirmed, setTransactionConfirmed] = useState({txn: '', success: false, status: 'IDLE'});

  let { tokensale, busd, usdt, provider, walletConnectProvider, popSheet } = useContext(EthereumContext);

  useEffect(() => {
    const getTokenSaleInfo = async() => {  
      try {

      const tokenSaleInfoTemp = await loadTokenSaleInfo(provider, tokensale);

      console.log(tokenSaleInfoTemp);

      setTokenSaleInfo(tokenSaleInfoTemp);

      }
      catch(err){
        console.error(err)
      }
    }
    
    getTokenSaleInfo();
    return () => [];
  }, []);

    useEffect(() => {
    const getBalanceInfo = async() => {  
      try {
      const userNetwork = await provider.getNetwork();
      if (userNetwork.chainId !== parseInt(process.env.REACT_APP_CHAIN_ID)) toast(`Please switch to BSC Smart Chain`, { type: 'error' });

      const signer = await provider.getSigner();

  	  const address = await signer.getAddress();

    	const bnbBalance = await signer.getBalance();
      
      const balanceInfoTemp = {};
      balanceInfoTemp['BNB'] = ethers.utils.commify(ethers.utils.formatEther(bnbBalance));
      balanceInfoTemp['BNB'] = Math.round(balanceInfoTemp['BNB'] * 100) / 100;

      const busdBalance = await busd.balanceOf(address);
      balanceInfoTemp['BUSD'] = ethers.utils.commify(ethers.utils.formatEther(busdBalance));
      balanceInfoTemp['BUSD'] = Math.round(balanceInfoTemp['BUSD'] * 100) / 100;

      const usdtBalance = await usdt.balanceOf(address);
      balanceInfoTemp['USDT'] = ethers.utils.commify(ethers.utils.formatEther(usdtBalance));
      balanceInfoTemp['USDT'] = Math.round(balanceInfoTemp['USDT'] * 100) / 100;

      setBalanceInfo(balanceInfoTemp);

      setTransactionConfirmed({txn: '', success: false, status: 'IDLE'});

      }
      catch(err){
        console.error(err)
      }
    }
    
    getBalanceInfo();
    return () => [];
  }, []);

  useEffect(() => {

    const interval = setInterval(async () => {
      try {

        const tokenSaleInfoTemp = await loadTokenSaleInfo(provider, tokensale);
 
        console.log(tokenSaleInfoTemp);
  
        setTokenSaleInfo(tokenSaleInfoTemp);
  
        }
        catch(err){
          console.error(err)
        }
    }, 20000);
    return () => clearInterval(interval);
  }, []);
  

    const toastId = useRef(null);
    const notifyTrans = () => toastId.current = toast("Transaction Sent. Confirming transaction in progress...", { autoClose: false, theme: "colored", type: toast.TYPE.INFO, closeButton: false, position: toast.POSITION.BOTTOM_CENTER, hideProgressBar: false });
    const confirmTrans = () => toast.update(toastId.current, { render: "Transaction Confirmed", type: toast.TYPE.SUCCESS, autoClose: 3000, closeButton: null, position: toast.POSITION.BOTTOM_CENTER, hideProgressBar: false });
    const errorTrans = (msg) => toastId.current = toast(msg, { autoClose: 5000, type: toast.TYPE.ERROR, closeButton: false, position: toast.POSITION.BOTTOM_CENTER, hideProgressBar: true });

  const paymentOptions = [
    // {
    //   key: 'BNB',
    //   text: 'BNB ',
    //   value: 'BNB',
    //   description: balanceInfo['BNB'] ? 'Balance: ' + balanceInfo['BNB'] : '...',
    //   image: { avatar: true, src: bnbLogo},
    // },
    {
      key: 'BUSD',
      text: 'BUSD ',
      value: 'BUSD',
      description: balanceInfo['BUSD'] ? 'Balance: ' + balanceInfo['BUSD'] : '...',
      image: { avatar: true, src: busdLogo},
    },
    {
      key: 'USDT',
      text: 'USDT ',
      value: 'USDT',
      description: balanceInfo['USDT'] ? 'Balance: ' + balanceInfo['USDT'] : '...',
      image: { avatar: true, src: usdtLogo},
    }
  ];

  const productOptions = [
    {
      key: 'BUNT',
      text: 'BUNT',
      value: 'BUNT',
      description:'',
      image: { avatar: true, src: buntLogo},
    }
  ];

  const [option, setOption] = useState('BUSD');

  const handlePaymentChange = (e, data) => {
    setOption(data.value)
    setPaymentAmount('');
    setPurchaseAmount('');
    validatePurchase('', '');
 }

 function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

 const handlePaymentAmountChange = (e) => {
  e.preventDefault();
  const value = replaceAll(e.target.value.toString(),',','');
  try{
    if(value > 0){
    console.log(value);
    const amount = parseFloat(value) * parseFloat(option === 'BNB' ? tokenSaleInfo['bnbRate'] : tokenSaleInfo['usdRate']);
    //setPaymentAmount(ethers.utils.commify(parseFloat(value).toFixed(12)));
    setPaymentAmount(value);
    setPurchaseAmount(parseFloat(amount).toFixed(2));
    validatePurchase(value, amount);
    }
    else{
      setPaymentAmount(e.target.value);
      setPurchaseAmount(ethers.utils.commify(0));
      validatePurchase(value, 0);
    }
}
catch(err){
  console.error(err.message);
  setPaymentAmount(e.target.value);
  setPurchaseAmount(0);
  validatePurchase(value, 0);
}
}

const handlePurchaseAmountChange = (e) => {
  e.preventDefault();
  const value = replaceAll(e.target.value.toString(),',','');
  try{
    if(value > 0){
      const amount = parseFloat(value) / parseFloat(option === 'BNB' ? tokenSaleInfo['bnbRate'] : tokenSaleInfo['usdRate']);
      console.log(option)
      //const amount = (option === 'BNB' ? parseFloat(value) / parseFloat(tokenSaleInfo['usdRate']) / (parseFloat(tokenSaleInfo['bnbPrice'])) : parseInt(value) / tokenSaleInfo['usdRate']);
      console.log(amount);
      //console.log((amount * 1000000000000000000).toFixed(18));
      setPurchaseAmount(value);
      setPaymentAmount(ethers.utils.commify( option === 'BNB' ? amount.toFixed(18) : parseFloat(amount).toFixed(12)));
      validatePurchase(amount, value);
    }
    else{
      setPurchaseAmount(e.target.value);
      setPaymentAmount(ethers.utils.commify(0));
      validatePurchase(0, e.target.value);
    }
}
catch(err){
  console.error(err);
  setPurchaseAmount(e.target.value);
  setPaymentAmount(0);
  validatePurchase(0, e.target.value);
}
}

function validatePurchase(amount, parchase) {
    setValidPurchase((parseFloat(parchase) >= tokenSaleInfo['minPurchaseBunt'] && parseFloat(parchase) <= tokenSaleInfo['maxPurchaseBunt']));
    let purchaseConfigTemp = {};
    if(parseFloat(parchase) < tokenSaleInfo['minPurchaseBunt'] || parseFloat(amount) == 0){
      purchaseConfigTemp['message'] = 'Amount is less than Minimum Purchase'
      purchaseConfigTemp['color'] = 'red'
    }
    else if(parseFloat(parchase) > tokenSaleInfo['maxPurchaseBunt']){
      purchaseConfigTemp['message'] = 'Amount is larger than Maximum Purchase'
      purchaseConfigTemp['color'] = 'red'
    }
    else if(!parchase){
      purchaseConfigTemp['message'] = 'Proceed Purchase'
      purchaseConfigTemp['color'] = 'grey'
    }
    else{
      purchaseConfigTemp['message'] = 'Proceed Purchase (' + ethers.utils.commify(parseFloat(parchase).toFixed(10)) + ' BUNTs )';
      purchaseConfigTemp['color'] = '#3442c4'
    }

    setPurchaseConfig(purchaseConfigTemp);
}

  const [submitting, setSubmitting] = useState(false);

  //const paymentOption = useState('BUSD');


  const purchase = async (event) => {
      event.preventDefault();
      const amount = replaceAll(paymentAmount.toString(),',','');
      const purchase = replaceAll(purchaseAmount.toString(),',','');
      setSubmitting(true);

      console.log('PREPARE PURCHASE: ' + amount);
      
      try {

        let response = null;
        // if(option === paymentOptions[0].key){
        //   response = await purchaseInBNB(provider, tokensale, amount, purchase, estimatedGas['gasPrice'], estimatedGas['gasLimit']);
        // }
        if(option === 'BUSD'){
          response = await purchaseInUSD(provider, tokensale, busd, amount, estimatedGas['gasPrice'], estimatedGas['gasLimit']);
        }
        else if(option === 'USDT'){
          response = await purchaseInUSD(provider, tokensale, usdt, amount, estimatedGas['gasPrice'], estimatedGas['gasLimit']);
        }
        else{
          errorTrans('Payment option not supported');
        }
        
        if(estimatedGas['gasPrice'] > 0 && estimatedGas['gasLimit'] > 0){

            console.log('confirm: Gas Price: ' + estimatedGas['gasPrice']);
            console.log('confirm: Gas Limit: ' + estimatedGas['gasLimit']);

            const hash = response.hash;
            const onClick = hash
              ? () => window.open(`${process.env.REACT_APP_CHAIN_EXPLORER}/tx/${hash}`)
              : undefined;

            //toast('Transaction sent!', { type: 'info', onClick });
            //setPaymentAmount('');
            //setPurchaseAmount('');
    
            //notifyTrans();
            //popSheet(false);
            console.log(hash.toString());
            setTransactionConfirmed({txn: hash.toString(), success: true, status: 'SENT'});
    
            provider.once(hash, (transaction) => {
              //toast('Transaction Confirmed!', { type: 'info', onClick });
              //confirmTrans();
              setEstimatedGas({gasPrice: 0, gasLimit: 0});
              //setConfirmStage(false);
              setTransactionConfirmed({txn: hash.toString(), success: true, status: 'CONFIRMED'});
          });   
        }
        else{
            setEstimatedGas(response);
            setConfirmStage(true);
            return;
        }

      } catch(err) {
            if(!confirmStage){
              setEstimatedGas({gasPrice: 0, gasLimit: 0});
              setConfirmStage(false);
            }

            if(err.data){
              errorTrans(err.data.message);
              console.error(err.data.message);
            }
            else{
              errorTrans(err.message);
              console.error(err.message);
            }

      } finally {
        setSubmitting(false);
      }
  }

  const setMaxPurchase = async (event) => {
    try{
    purchaseAmountInput.current.focus();
    const purchase = (tokenSaleInfo['maxPurchaseBunt']) / (option === 'BNB' ? tokenSaleInfo['bnbRate'] : tokenSaleInfo['usdRate']);
    setPurchaseAmount(tokenSaleInfo['maxPurchaseBunt']);
    setPaymentAmount(ethers.utils.commify(purchase.toFixed(18)));
    validatePurchase(ethers.utils.commify(parseFloat(purchase)), tokenSaleInfo['maxPurchaseBunt']);
  }
    catch(err){
      
    }
  }

  const seMinPurchase = async (event) => {    
    try{
    purchaseAmountInput.current.focus();
    const purchase = (tokenSaleInfo['minPurchaseBunt']) / (option === 'BNB' ? tokenSaleInfo['bnbRate'] : tokenSaleInfo['usdRate']);
    setPurchaseAmount(tokenSaleInfo['minPurchaseBunt']);
    setPaymentAmount(ethers.utils.commify(purchase.toFixed(18)));
    validatePurchase(ethers.utils.commify(parseFloat(purchase)), tokenSaleInfo['minPurchaseBunt']);
    }
    catch(err){

    }
  }

  const resetPurchase = async (event) => {
      setConfirmStage(false);
      setEstimatedGas({gasPrice: 0, gasLimit: 0});
  }

  const openTansLink = async (event) => {
    window.open(`${process.env.REACT_APP_CHAIN_EXPLORER}/tx/${transactionConfirmed['txn'].toString()}`)
}

  function isConfrimationStage() {
    if(!confirmStage){
      return <div className="Tokensale-Page">
      <p className="ConfirmTokenSale-Header">
        BUNT Token Purchase
      </p>
      <div>
        <div className="Tokensale-Page-Input">
          <Input className="Amount-Input" transparent size={isMobile ? 'medium' : 'large'} required={true} placeholder={'Enter BUNT purchase amount'} style={{width:'95%'}} ref={purchaseAmountInput} 
          onChange={handlePurchaseAmountChange}
          value={purchaseAmount ? purchaseAmount : ''}
          error={!validPurchase}
          action={
          <Dropdown id="purchase-options"
          inline
          pointing={'top right'}
          options={productOptions}
          defaultValue={productOptions[0].value}
          onChange={handlePaymentChange}
        />
          }
          />
        </div>
      </div>
      <div>
      <Label size={isMobile ? 'small' : 'medium'} tag pointing="above" color='blue' style={{width:'50%', margin:'0.2rem', marginBottom: '0.7rem'}}>
        SPECIAL BONUS
        <Label.Detail>{ tokenSaleInfo['bonusRate'] ? ethers.utils.commify(parseFloat(tokenSaleInfo['bonusRate'] * purchaseAmount / 100).toFixed(2)) + ' BUNT (' + tokenSaleInfo['bonusRate'] + '%)': '...'}</Label.Detail>
      </Label>
      </div>
      <div>
        <div className="Tokensale-Page-Input">
          <Input class="Amount-Input" transparent size={isMobile ? 'medium' : 'large'} required={true} placeholder={'Amount in ' + option} style={{width:'95%'}} ref={paymentAmountInput} 
          onChange={handlePaymentAmountChange}
          value={paymentAmount ? paymentAmount : ''}
          action={
          <div>
        <Dropdown id="purchase-options"
          inline
          pointing={'top right'}
          options={paymentOptions}
          defaultValue={paymentOptions[0].value}
          onChange={handlePaymentChange}
        />
        </div>
          }
          />
        </div>
      </div>
      <div className="Tokensale-Page-Info">
        {/* <Label size={isMobile ? 'medium' : 'medium'} color='teal' style={{margin:'0.2rem'}}>
        {'1 USD = '}
        <Label.Detail>{option === 'BNB' ? ethers.utils.commify(tokenSaleInfo['bnbRate'] ? tokenSaleInfo['bnbRate']  : "0") + ' BUNTs' : ethers.utils.commify(tokenSaleInfo['usdRate'] ? tokenSaleInfo['usdRate']  : "0") + ' BUNTs'}</Label.Detail>
      </Label> */}
      <div style={{width:'100%', margin:'0.2rem'}}>
      <Label size={isMobile ? 'small' : 'medium'} as='a' color='teal' onClick={seMinPurchase} style={{width:'49.6%', margin:'0.2rem 0.1rem 0.2rem 0'}}>
        Min Purchase
        <Label.Detail>{ tokenSaleInfo['minPurchaseBunt'] ? ethers.utils.commify(tokenSaleInfo['minPurchaseBunt']) + ' BUNT = $' + ethers.utils.commify(parseFloat(tokenSaleInfo.minPurchaseBunt * tokenSaleInfo.buntPrice).toFixed(2)): '...'}</Label.Detail>
      </Label>
      <Label size={isMobile ? 'small' : 'medium'} as='a' color='teal' onClick={setMaxPurchase} style={{width:'49.6%', margin:'0.2rem 0 0.2rem 0.1rem'}}>
        Max Purchase
        <Label.Detail>{ tokenSaleInfo['maxPurchaseBunt'] ? ethers.utils.commify(tokenSaleInfo['maxPurchaseBunt'])  + ' BUNT = $' + ethers.utils.commify(parseFloat(tokenSaleInfo.maxPurchaseBunt * tokenSaleInfo.buntPrice).toFixed(2)): '...'}</Label.Detail>
      </Label>
      </div>
      </div>
       <form onSubmit={purchase}>
       <button disabled={!validPurchase} style={{backgroundColor: purchaseConfig['color']}}>{submitting ? 'Preparing Purchase...' : purchaseConfig['message']}</button>
       </form>
       <p className="ConfirmTokenSale-Note" hidden={!validPurchase}>
         { option === 'BUSD' || option === 'USDT' ? '*Prior proceeding, you will be asked to confirm approving ' + paymentAmount + ' ' + option + ' spend limit by BUNT CHAIN' : ''}
      </p>
    </div>
    }
    else{
      return <div className="ConfirmTokenSale">
      <p className="ConfirmTokenSale-Header">
        Confirm Purchasing
      </p>
      <div className='ConfirmTokenSale-Purchase-Container'>
      <div><p className="ConfirmTokenSale-Purchase">{purchaseAmount + ' BUNT'}</p></div>
      <div hidden={transactionConfirmed['success']}>
      <Button className='ui colorTrans button' icon='edit' circular size='mini' onClick={resetPurchase}/>
      </div>
      </div>
      <div>
      <Label size={isMobile ? 'small' : 'medium'} color='teal' style={{width: '50%', fontSize:'1.1rem', marginBottom: '2rem', fontFamily: 'Titillium Web, sans-serif'}}>
          {'NET: '}{ (parseFloat(purchaseAmount) + parseFloat(purchaseAmount * tokenSaleInfo['bonusRate'] / 100)) + ' BUNT'}
        <Label.Detail  style={{fontSize:'0.9rem', paddingRight: '1rem',  paddingTop: '0.2rem'}}>+50% SPECIAL BONUS</Label.Detail>
      </Label>
      </div>
      <p className="ConfirmTokenSale-Payment">
         for a payment of <span>{paymentAmount + ' ' + option}</span>
      </p>
      <p className="ConfirmTokenSale-Note">
         {'Approximate gas required to execute this transaction is ' + ethers.utils.formatUnits((estimatedGas['gasLimit']), 8) + ' BNB'}
      </p>
   <form onSubmit={purchase}>
       <button disabled={transactionConfirmed['success']} style={{backgroundColor: transactionConfirmed['success'] ? (transactionConfirmed['status'] == 'SENT' ? '#4E5DE4' : 'teal') : '#4E5DE4'}}>{submitting ? 'Confirming Purchase...' : (transactionConfirmed['success'] ? 'Transaction ' + transactionConfirmed['status'] + (transactionConfirmed['status'] == 'SENT' ? ', Confirming...' : '') : 'CONFIRM')}</button>
    </form>
    <p className="ConfirmTokenSale-Confirmed" hidden={!transactionConfirmed['success']}>
         {/* {transactionConfirmed['status'] == 'SENT' ? 'Transaction Sent, waiting for confirmation...' : 'Transaction Confirmed'} */}
      </p>
      <div hidden={!transactionConfirmed['success']}>
      <Label as='a' onClick={openTansLink} size={isMobile ? 'medium' : 'medium'} color={transactionConfirmed['status'] == 'SENT' ? 'blue' : 'teal' } style={{margin:'auto'}} >
        <Icon name='external alternate'/>Click to view transaction on Bscscan
      </Label>
      </div>
    </div>
    }
  }

  return <center>
      {isConfrimationStage()}
    </center>
  
}

export default TokenSale;